import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(theme => ({
  buttonPosition: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2%'
  },
  buttonTypo: {
    '@media (max-width:500px)': {
      fontSize: '0.6rem !important',
      paddingTop: '3%'
    },
  },
  secondTitle: {
    paddingRight: '5%',
    paddingLeft: '5%',
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  missions: {
    paddingRight: '5%',
    paddingLeft: '5%',
    paddingTop: '1%',
    lineHeight: '2rem'
  },
  bold: {
    fontWeight: 700
  },
  pdf: {
    width: 800,
    height: 900,
    marginBottom: "5%",
    '@media (max-width:900px)': {
      width: 400,
      height: 450
    },
    '@media (max-width:400px)': {
      width: 300,
      height: 350
    },
  },
  downloadBox: {
    display: 'none',
    '@media (max-width:900px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  downloadTitle:{
    fontWeight: 600
  },
  icon: {
    color: '#C45824'
  },
}));
