import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Box, Typography, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';
import Member from '../components/Member/member';
import Hero from '../components/Hero/index';
import Title from '../components/Title/index';
import CustomButton from '../components/CustomButton/customButton';
import useStyles from '../pagesStyles/conseilAdministratifStyles';
import './reset.css'

const MembersPage = ({ data }) => {

  const classes = useStyles();

  const count = data.allContentfulMembres.pageInfo.itemCount;
  const pdf = data?.contentfulPdfPresentationSaiil?.pdf?.file?.url;

  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>

      <Helmet>
        <html lang="fr" />
        <title>{frontmatter.SEOtitle}</title>
        <meta name="description" content={frontmatter.SEOdescription} />
      </Helmet>

      <Hero
        image={frontmatter.coverImage.childImageSharp.gatsbyImageData}
        title={frontmatter.title}
      />

      <Typography className={classes.missions} variant="body1" component="p">
        {frontmatter.phrase1first}<span className={classes.bold}> {count} membres </span>{frontmatter.phrase1second}
      </Typography>
      <Typography className={classes.missions} variant="body1" component="p">
        {frontmatter.phrase2first} <span className={classes.bold}>{frontmatter.phrase2second}</span>{frontmatter.phrase2third}
      </Typography>
      <Typography className={classes.missions} variant="body1" component="p">
        {frontmatter.phrase3}
      </Typography>

      {data.allContentfulMembres.edges.map((edge, key) => {
        return (
          <Member
            key={key}
            image={edge.node.img.file.url}
            alt={edge.node.img.description}
            title={edge.node.title}
            description={edge.node.description}
            id={edge.node.contentful_id}
          />
        )
      })}

      {/* SECTION Notre raisons d'être */}
      <Box className={classes.secondTitle}>
        <Title title={frontmatter.subtitle} />
      </Box>
      {/* TODO: intégration PDF */}
      {pdf &&
        <Grid container direction="column" alignItems="center">
          <Box m={2}>
            <Grid container direction="column" alignItems="center">
              <iframe src={pdf} title='pdf' className={classes.pdf} />
            </Grid>
            <Grid className={classes.downloadBox}>
              <Box mr={3}>
                <Typography className={classes.downloadTitle}>Ouvrir la plaquette en pdf</Typography>
              </Box>
              <a href={pdf} target="_blank" rel="noreferrer" download>
                <FontAwesomeIcon icon={faFilePdf} size="3x" className={classes.icon} />
              </a>
            </Grid>
          </Box>
        </Grid>
      }
      <Typography dangerouslySetInnerHTML={{ __html: html }} className={classes.missions} variant="body1" component="div" />

      <Box className={classes.buttonPosition}>
        <CustomButton
          className={classes.buttonTypo}
          text="EN SAVOIR PLUS SUR L'HISTOIRE DU SAIL"
          buttonStyle="secondaryButton"
          link="/histoire-du-saiil" />
      </Box>

    </Layout>
  )
}

export default MembersPage

export const query = graphql`
  query {
    allContentfulMembres(sort: {fields: priority, order: ASC}) {
      edges {
        node {
          contentful_id
          img {
            description
            file {
              url
            }
          }
          title
          description {
            raw
          }
        }
      }
      pageInfo {
        itemCount
      }
    },
    contentfulPdfPresentationSaiil {
      pdf {
        file {
          url
        }
      }
    },
    markdownRemark(fileAbsolutePath: {regex: "/conseil-administratif.md/"}) {
    	html
      frontmatter {
        SEOtitle
        SEOdescription
        title
        phrase1first
        phrase1second
        phrase2first
        phrase2second
        phrase2third
        phrase3
        subtitle
        mission1Title
        mission1Description
        mission1Keys
        mission1FirstKeyTitle
        mission1FirstKeyText
        mission1SecondKeyTitle
        mission1SecondKeyText
        mission1ThirdKeyTitle
        mission1ThirdKeyText
        mission1FourthKeyTitle
        mission1FourthKeyText
        mission2Title
        mission2Description
        mission3Title
        mission3Description
        mission4Title
        mission4Description
        coverImage {
          childImageSharp {
              gatsbyImageData
          }
        }
      }
    }
  }
`
