import makeStyles from '@material-ui/core/styles/makeStyles';


export default makeStyles(theme => ({

pictureStyle: {
    display: 'flex',
    width: '30%',
    '@media (max-width:800px)': {
        width: '100%',
        justifyContent: 'center'
    
    },
},

orange: {
    backgroundColor: theme.palette.primary.mainBGColor,
    height: '20%',
    width: '70%',
    boder: 'solid 1px #000',
    '@media (max-width:1700px)': {
        width: '40%',
    },
    '@media (max-width:800px)': {
        display: 'none',
    },
},

memberContainer: {
    display: 'flex', 
    justifyContent: 'center',
    alignContent: 'space around',
    paddingTop: '2%',
    paddingBottom: '1%',
    '@media (max-width:900px)': {
        display: 'inline-flex',
        flexFlow: 'column',
        alignItems: 'center',
        textAlign: 'center'
        
    },
},

textBlock: {
    width: '70%',
    '@media (max-width:900px)': {
        paddingTop: '5%',
        width: '90%'
    },
},

memberName: {
    textTransform: 'capitalize',
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: '8%',
    '@media (max-width:900px)': {
        backgroundColor: theme.palette.primary.mainBGColor,
        color: theme.palette.secondary.main,
        fontSize: 18,
    },
    '@media (max-width:450px)': {
        backgroundColor: theme.palette.primary.mainBGColor,
        color: theme.palette.secondary.main,
        fontSize: 15
    },

},

memberImage: {
    width: '230px !important',
    height: '230px !important',
    marginLeft: '-20%',
    '@media (max-width:1700px)': {
        marginLeft: '-27%',
    },
    '@media (max-width:1100px)': {
        marginLeft: '-35%',
    },
    '@media (max-width:900px)': {
        width: '200px !important',
        height: '200px !important',
        marginLeft: '0px !important'
        
    },
    '@media (max-width:800px)': {
        width: '150px !important',
        height: '150px !important',
        marginLeft: '0px !important'
    },

},

description: {
    textAlign: 'justify',
    paddingRight: '20%',
    padding: 0,
    '@media (max-width:900px)': {
        paddingRight: 0,
    },
}

}));
