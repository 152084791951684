import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar, Typography } from '@material-ui/core';
import styles from './styles';
import RichTextRenderer from '../RichTextRenderer/FromGatsby'

const Member = ({ image, title, description, id, alt }) => {
  const classes = styles();

  return (
    <Box pt={3}>
      <div style={{ top: '-250px', position: 'relative' }} id={id}></div> {/* KEEP ! Invisible div with offset to handle redirection from the search results page */}
      <div className={classes.memberContainer}>
        <div className={classes.pictureStyle}>
          <div className={classes.orange}></div>
          <Avatar className={classes.memberImage} src={image} alt={alt}></Avatar>
        </div>

        <div className={classes.textBlock}>
          <Typography className={classes.memberName} variant="h5" component="h3">
            {title}
          </Typography>
          <Box className={classes.description}>
            <RichTextRenderer details={description} />
          </Box>
        </div>
      </div>
    </Box>
  )

}

export default Member

Member.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired
};
